import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Style from "./style.module.css"
import ArrowHeadIcon from "../../images/arrow-head.svg"

import Slider from "infinite-react-carousel"

const SubSectionCarousel = () => {

    const data = useStaticQuery(graphql`
	    query {
            viver1: file(relativePath: { eq: "viver/1.jpg" }) {
    	        childImageSharp {
    	          fluid(maxWidth: 800) {
    	            ...GatsbyImageSharpFluid
    	          }
    	        }
            },
            viver2: file(relativePath: { eq: "viver/2.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            viver3: file(relativePath: { eq: "viver/3.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            viver4: file(relativePath: { eq: "viver/4.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            viver5: file(relativePath: { eq: "viver/5.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            viver6: file(relativePath: { eq: "viver/6.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            viver7: file(relativePath: { eq: "viver/7.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            viver8: file(relativePath: { eq: "viver/8.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            viver9: file(relativePath: { eq: "viver/9.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
        }
    `)

    const settings =  {
      adaptiveHeight: false,
      dots: true,
      prevArrow:  <div> <ArrowHeadIcon className={Style.prev}/> </div>,
      nextArrow: <div> <ArrowHeadIcon className={Style.next}/> </div>
    };

    return (
        <div className={Style.container}>
            {typeof window !== 'undefined' && Slider &&  <Slider { ...settings }>
                <div>
                    <Img fluid={data.viver1.childImageSharp.fluid} />
                </div>
                <div>
                    <Img fluid={data.viver2.childImageSharp.fluid} />
                </div>
                <div>
                    <Img fluid={data.viver3.childImageSharp.fluid} />
                </div>
                <div>
                    <Img fluid={data.viver4.childImageSharp.fluid} />
                </div>
                <div>
                    <Img fluid={data.viver5.childImageSharp.fluid} />
                </div>
                <div>
                    <Img fluid={data.viver6.childImageSharp.fluid} />
                </div>
                <div>
                    <Img fluid={data.viver7.childImageSharp.fluid} />
                </div>
                <div>
                    <Img fluid={data.viver8.childImageSharp.fluid} />
                </div>
                <div>
                    <Img fluid={data.viver9.childImageSharp.fluid} />
                </div>
            </Slider>}
        </div>
    )
}

export default SubSectionCarousel