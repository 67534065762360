import React, {useState} from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Style from "./style.module.css"

import Section from "../Section"
import TitleSection from "../TitleSection"
import SubSectionCarousel from "./SubSectionCarousel/SubSectionCarousel"

import LocalizacaoIcon from "../../images/localizacao.svg"
import ValorIcon from "../../images/valor.svg"
import PlantaIcon from "../../images/planta.svg"
import LancamentoIcon from "../../images/lancamento.svg"

import LindoiaIcon from "../../images/lindoia.svg"
import CenariuIcon from "../../images/cenariu.svg"
import EcovilIcon from "../../images/ecovil.svg"
import CipestreIcon from "../../images/cipestre.svg"

import LindoiaIconActive from "../../images/lindoia_active.svg"
import CenariuIconActive from "../../images/cenariu_active.svg"
import EcovilIconActive from "../../images/ecovil_active.svg"
import CipestreIconActive from "../../images/cipestre_active.svg"

import ArrowHeadIcon from "../../images/arrow-head.svg"
import Slider from "infinite-react-carousel"

const SectionAtivos = () => {

    const [tab, setTab] = useState(1)

    const data = useStaticQuery(graphql`
        query {
            one: file(relativePath: { eq: "one.png" }) {
                childImageSharp {
                  fixed(width: 50) {
                    ...GatsbyImageSharpFixed
                  }
                }
            },
            plus: file(relativePath: { eq: "plus.png" }) {
                childImageSharp {
                  fixed(width: 50) {
                    ...GatsbyImageSharpFixed
                  }
                }
            },
            ecoville1: file(relativePath: { eq: "ecoville/1.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            ecoville2: file(relativePath: { eq: "ecoville/2.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            ecoville3: file(relativePath: { eq: "ecoville/3.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            ecoville4: file(relativePath: { eq: "ecoville/4.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            ecoville5: file(relativePath: { eq: "ecoville/5.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            ecoville6: file(relativePath: { eq: "ecoville/6.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            ecoville7: file(relativePath: { eq: "ecoville/7.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            ecoville8: file(relativePath: { eq: "ecoville/8.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            ecoville9: file(relativePath: { eq: "ecoville/9.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            cipreste1: file(relativePath: { eq: "cipreste/1.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            cipreste2: file(relativePath: { eq: "cipreste/2.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            cipreste3: file(relativePath: { eq: "cipreste/3.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            cipreste4: file(relativePath: { eq: "cipreste/4.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            cipreste5: file(relativePath: { eq: "cipreste/5.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            cipreste6: file(relativePath: { eq: "cipreste/6.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            cipreste7: file(relativePath: { eq: "cipreste/7.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
            cipreste8: file(relativePath: { eq: "cipreste/8.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid
                  }
                }
            },
        }
    `)

    const settings_desktop =  {
      adaptiveHeight: false,
      dots: true,
      prevArrow:  <div> <ArrowHeadIcon className={Style.prev}/> </div>,
      nextArrow: <div> <ArrowHeadIcon className={Style.next}/> </div>,
      slidesToShow: 3
    };

    const settings_mobile =  {
      adaptiveHeight: false,
      dots: true,
      prevArrow:  <div> <ArrowHeadIcon className={Style.prev}/> </div>,
      nextArrow: <div> <ArrowHeadIcon className={Style.next}/> </div>,
    };

	return (
        <div>
            <Section id="empreendimentos">
                <TitleSection>Ativos alvo do LUGGO Fundo de Investimento Imobiliário</TitleSection>
                <div className={Style.content}>
                    <p className={Style.description}>
                        O FII conta com os quatro primeiros empreendimentos da Luggo,
                        localizados em <strong>Campinas, Belo Horizonte e Curitiba.</strong> Porém, esse número
                        deve aumentar. Isso porque o modelo de negócio consiste em construir
                        os imóveis e transferi-los para o fundo assim que eles forem alugados.
                    </p>
                </div>
                <div className={Style.container_tab}>
                    <div className={Style.tab} onClick ={() => { setTab(1) }} > 
                        {tab != 1 && <CipestreIcon />}
                        {tab == 1 && <CipestreIconActive />}
                    </div>
                    <div className={Style.tab} onClick ={() => { setTab(2) }}> 
                        {tab != 2 && <EcovilIcon />}
                        {tab == 2 && <EcovilIconActive />}
                    </div>
                    <div className={Style.tab} onClick ={() => { setTab(3) }}> 
                        {tab != 3 && <CenariuIcon />}
                        {tab == 3 && <CenariuIconActive />}
                    </div>
                    <div className={Style.tab} onClick ={() => { setTab(4) }}> 
                        {tab != 4 && <LindoiaIcon />}
                        {tab == 4 && <LindoiaIconActive />}
                    </div>  
                </div>
            </Section>
            { tab == 1 &&
            <Section bgColor="gray">
                <div className={Style.mobile}>
                {typeof window !== 'undefined' && Slider && <Slider { ...settings_mobile }>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste1.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste2.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste3.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste4.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste5.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste6.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste7.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste8.childImageSharp.fluid} />
                    </div>
                </Slider>}
                </div>
                <div className={Style.desktop}>
                {typeof window !== 'undefined' && Slider && <Slider { ...settings_desktop }>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste1.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste2.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste3.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste4.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste5.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste6.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste7.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.cipreste8.childImageSharp.fluid} />
                    </div>
                </Slider>}
                </div>
                <div className={Style.container_card}>
                    <div className={Style.card}>
                        <LocalizacaoIcon />
                        <p className={Style.description_card}>
                            Localizado na rua das Espatódias, 610, no bairro Marajó
                            em Belo Horizonte.
                        </p>
                    </div>
                    <div className={Style.card}>
                        <Img fixed={data.one.childImageSharp.fixed} />
                        <p className={Style.description_card}>
                            Primeiro empreendimento a integrar nosso Fundo de Investimento Imobiliário.
                        </p>
                    </div>
                    <div className={Style.card}>
                        <LancamentoIcon />
                        <p className={Style.description_card}>
                            Lançado em Janeiro de 2019.
                        </p>
                    </div>
                    <div className={Style.card}>
                        <PlantaIcon />
                        <p className={Style.description_card}>
                            Conta com 116 apartamentos de 2 quartos e 41,85m².
                        </p>
                    </div>
                    <div className={Style.card}>
                        <ValorIcon />
                        <p className={Style.description_card}>
                            O valor médio da locação dos apartamentos que o compõem é de R$1021,00.
                        </p>
                    </div>
                    <div className={Style.card}>
                        <Img fixed={data.plus.childImageSharp.fixed} />
                        <p className={Style.description_card}>
                            100% alocado e com mais de 73% de satisfação.
                        </p>
                    </div>
                </div>
            </Section> }
            { tab == 2 &&
            <Section bgColor="gray">
                <div className={Style.mobile}>
                {typeof window !== 'undefined' && Slider && <Slider { ...settings_mobile }>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville1.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville2.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville3.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville4.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville5.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville6.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville7.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville8.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville9.childImageSharp.fluid} />
                    </div>
                </Slider>}
                </div>
                <div className={Style.desktop}>
                {typeof window !== 'undefined' && Slider && <Slider { ...settings_desktop }>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville1.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville2.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville3.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville4.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville5.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville6.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville7.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville8.childImageSharp.fluid} />
                    </div>
                    <div className={Style.image}>
                        <Img fluid={data.ecoville9.childImageSharp.fluid} />
                    </div>
                </Slider>}
                </div>
                <div className={Style.container_card}>
                    <div className={Style.card}>
                        <LocalizacaoIcon />
                        <p className={Style.description_card}>
                            Localizado na rua Casemiro Augusto Rodacki, 287 em Curitiba-PR.
                        </p>
                    </div>
                    <div className={Style.card}>
                        <LancamentoIcon />
                        <p className={Style.description_card}>
                            Lançado recentemente em outubro de 2019.
                        </p>
                    </div>
                    <div className={Style.card}>
                        <PlantaIcon />
                        <p className={Style.description_card}>
                            Plantas de 2 quartos (46,91m²) e 2 quartos com suíte (50,70m²).
                        </p>
                    </div>
                    <div className={Style.card}>
                        <ValorIcon />
                        <p className={Style.description_card}>
                            Seu valor médio de tabela é de R$1.604.
                        </p>
                    </div>
                    <div className={Style.card}>
                        <Img fixed={data.plus.childImageSharp.fixed} />
                        <p className={Style.description_card}>
                            80% de seu total de apartamentos já alugados nos 2 primeiros meses.
                        </p>
                    </div>
                </div>
            </Section> }
            {tab == 3 &&
            <Section  bgColor="gray">
                <div className={Style.container_card}>
                    <div className={Style.card}>
                        <LocalizacaoIcon />
                        <p className={Style.description_card}>
                            Localizado na Rua Santa Maria Rosselo, 180, no bairro Mansões Santo Antônio em Campinas-SP.
                        </p>
                    </div>
                    <div className={Style.card}>
                        <LancamentoIcon />
                        <p className={Style.description_card}>
                            Data de lançamento do empreendimento em maio de 2020.
                        </p>
                    </div>
                    <div className={Style.card}>
                        <PlantaIcon />
                        <p className={Style.description_card}>
                            Plantas: 1 quarto (36,73m²) e 2 quartos (média de 47,49 m²).
                        </p>
                    </div>
                    <div className={Style.card}>
                        <ValorIcon />
                        <p className={Style.description_card}>
                            Valor médio de tabela é R$1.500,00.
                        </p>
                    </div>
                </div>
            </Section> }
            { tab == 4 &&
            <Section  bgColor="gray">
                <div className={Style.container_card}>
                    <div className={Style.card}>
                        <LocalizacaoIcon />
                        <p className={Style.description_card}>
                            Localizado na Rua Capitão João Zaleski, 750, no bairro de Lindóia em Curitiba-SP.
                        </p>
                    </div>
                    <div className={Style.card}>
                        <LancamentoIcon />
                        <p className={Style.description_card}>
                            Data de lançamento do empreendimento em março de 2020.
                        </p>
                    </div>
                    <div className={Style.card}>
                        <PlantaIcon />
                        <p className={Style.description_card}>
                            Plantas: 2 quartos (47,41m²) e 2 quartos com suíte (51,18m²).
                        </p>
                    </div>
                    <div className={Style.card}>
                        <ValorIcon />
                        <p className={Style.description_card}>
                            Valor médio de tabela é R$1.300,00.
                        </p>
                    </div>
                    <div className={Style.card}>
                        <Img fixed={data.plus.childImageSharp.fixed} />
                        <p className={Style.description_card}>
                            88% dos apartamentos tem varanda com churrasqueira.
                        </p>
                    </div>
                </div>
            </Section> }
        </div>
	);
}

export default SectionAtivos