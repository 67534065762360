import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Style from "./style.module.css"

const Hero = () => {

	const data = useStaticQuery(graphql`
	    query {
	      mrv: file(relativePath: { eq: "mrv.png" }) {
	        childImageSharp {
	          fixed {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      },
	      luggo: file(relativePath: { eq: "luggo.png" }) {
	        childImageSharp {
	          fixed {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      }
	    }
	`)


	return (
		<section className={Style.hero}>
			<div className={Style.hero_overlay}></div>
			<div className={Style.content}>
				<div className="container">
					<h1 className={Style.title}>
						Chegou o <span>LUGG11</span>: 1º fundo de investimento imobiliário <span>residencial</span> do Brasil.
					</h1>
					<div className={Style.separator}></div>
					<div className={Style.logos}>
						<div className={Style.row}>
							<Img fixed={data.mrv.childImageSharp.fixed} />
							<Img fixed={data.luggo.childImageSharp.fixed} />
							<div className={Style.desktop_separator}></div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Hero