import React from "react"

import Style from "./style.module.css"

import Section from "../Section"
import LuggoVideo from "../../videos/luggo.mp4"

const SectionVideo = () => {
	return (
		<Section>
			<div className="negative-container">
				<div className={Style.content}>
					<iframe width="1000" height="600" src="https://www.youtube-nocookie.com/embed/xwXnBVIYyHA?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
			</div>
		</Section>
	)
}

export default SectionVideo