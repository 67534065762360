import React from "react"

import Style from "./style.module.css"

const TitleSubSection = (props) => {
	return (
		<h3 className={Style.title}> {props.children} </h3>
	);
}

export default TitleSubSection