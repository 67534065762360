import React from "react"

import Style from "./style.module.css"

import Liquidez from "../../images/liquidez.svg"
import Hand from "../../images/hand.svg"
import Pizza from "../../images/pizza.svg"
import Coin from "../../images/coin.svg"
import Eye from "../../images/eye.svg"

const VantagensCards = () => {
	return (
            <div className={Style.Vantagem}>
				<div className={Style.content}>
                    <div className={Style.titleImage}>
                        <Liquidez />
                        <h3 className={Style.titleVantagem}>Liquidez</h3>
                    </div>
					<p className={Style.text}>
                        A cota de um Fundo Imobiliário é muito mais líquida, ou seja, tem um prazo de resgate menor e uma <strong>conversão do ativo em dinheiro mais rápida</strong> que a de um imóvel, por exemplo.
                    </p>
				</div>
                <div className={Style.content}>
                    <div className={Style.titleImage}>
                        <Hand />
                        <h3 className={Style.titleVantagem}>Facilidade</h3>
                    </div>
					<p className={Style.text}>
                        A simplicidade e facilidade de investir em um FII pode ser identificada na hora de vender suas cotas e de acompanhar a evolução do seu investimento. Além da vantajosa liquidez, a negociação das cotas é caracterizada por ter <strong>pouca burocracia e menos trâmites legais</strong> que a maioria dos outros investimentos.
                    </p>
				</div>
                <div className={Style.content}>
                    <div className={Style.titleImage}>
                        <Pizza />
                        <h3 className={Style.titleVantagem}>Diversificação</h3>
                    </div>
					<p className={Style.text}>
                        Outra grande vantagem que pode resultar da escolha de um Fundo de Investimento Imobiliário é o <strong>aumento da rentabilidade a médio e longo prazo</strong> por conta da diversificação dos ativos. Além da <strong>potencialização dos ganhos</strong>, diversificar as aplicações de uma carteira de investimentos contribui em vários aspectos na jornada de um investidor. Os riscos, por exemplo, são efetivamente minimizados quando há equilíbrio entre os diferentes componentes de uma carteira.
                    </p>
				</div>
                <div className={Style.content}>
                    <div className={Style.titleImage}>
                        <Coin />
                        <h3 className={Style.titleVantagem}>Custos de Aquisição</h3>
                    </div>
					<p className={Style.text}>
                        Se a aquisição de um imóvel requer o pagamento de comissão imobiliária, tributos e taxas de cartório, a transação de uma cota do Fundo Imobiliário tem apenas o custo de corretagem (em alguns casos, de acordo com a sua corretora) e, por isso, <strong>sai muito mais barata.</strong>
                    </p>
				</div>
                <div className={Style.fiscal}>
                    <div className={Style.fiscalContainer}>
                        <div className={Style.titleImage}>
                            <Eye />
                            <h3 className={Style.titleVantagem}>Isenção Fiscal</h3>
                        </div>
                        <p className={Style.text}>
                            Uma pessoa física que adquire cotas de um Fundo de Investimento Imobiliário têm seus <strong>rendimentos isentos do Imposto de Renda</strong>, uma vez que as seguintes condições sejam contempladas:
                        </p>
                    </div>
                    <div className={Style.fiscalContainer}>
                        <p className={Style.text}><span className={Style.number}>1º</span> O cotista precisa ter no máximo <strong>10% das cotas totais do Fundo</strong> e ter direito a receber um rendimento de também no máximo 10% do rendimento auferido pelo Fundo.</p>
                        <p className={Style.text}><span className={Style.number}>2º</span> A negociação das cotas deve ser <strong>exclusiva de bolsas de valores</strong> e do mercado de balcão organizado.</p>
                        <p className={Style.text}><span className={Style.number}>3º</span> As cotas do Fundo precisam ser distribuídas entre, no mínimo, <strong>50 cotistas.</strong></p>
                    </div>
                </div>
			</div>
        )
    }

export default VantagensCards