import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Style from "./style.module.css"

const SubSectionViver = () => {

    const data = useStaticQuery(graphql`
	    query {
	      viver: file(relativePath: { eq: "viver-bem.png" }) {
	        childImageSharp {
	          fixed {
	            ...GatsbyImageSharpFixed
	          }
	        }
          }
        }
    `)

	return (
        <div>
            <div className={Style.content}>
                <div className={Style.image}>
                    <Img fixed={data.viver.childImageSharp.fixed} />
                </div>
                <p className={Style.description}>
                    <h4 className={Style.subtitle}> Viver Luggo </h4>

                    <p>O conceito de “Viver Luggo” vai muito além da oferta de <b>serviços e
                    facilidades tecnológicas</b>. Todos os empreendimentos vinculados ao Luggo FII contam
                    com categorias de serviço especialmente pensadas para proporcionar um ambiente
                    confortável e atualizado para os moradores e visitantes.
                    <br></br>
                    Pensando no novo modelo
                    de consumo, a <b>economia compartilhada</b> está presente em todos os empreendimentos.
                    Com o diferencial dos <b>serviços pay per use</b>, sendo muitos deles inéditos no
                    mercado de locação: </p>
                </p>
            </div>
        </div>
	)
}

export default SubSectionViver