import React from "react"

import Style from "./style.module.css"

import People from "../../images/people.svg"

import Section from "../Section"
import TitleSection from "../TitleSection"

const SectionComo = () => {
	return (
		<Section id="aquisicao">
			<TitleSection  className={Style.title}> Como adquirir uma cota do FII Luggo? </TitleSection>
			<div className={Style.content}>
                <div className={Style.image}>
                    <People />
                </div>
				<div className={Style.description}>
                    <h4 className={Style.subtitle}>
                        Agora você tem uma grande oportunidade nas mãos!
                    </h4>
                    <p >
                        O momento é mais do que propício para <strong>aproveitar o
                        aquecimento do mercado imobiliário e aumentar a liquidez
                        dos seus investimentos.</strong>
                    </p>
                    <p> Finalizada a primeira oferta
                        pública, todas as cotas dos primeiros quatro
                        empreendimentos do ativo alvo foram negociadas.
                        O que quer dizer que para adquirir uma cota destes
                        primeiros empreendimentos <strong>você deverá negociar
                        diretamente com as corretoras que atuam na B3.</strong>
                    </p>
                </div>
			</div>
		</Section>
	)
}

export default SectionComo