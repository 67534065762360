import React from "react"

import Style from "./style.module.css"

import Section from "../Section"
import TitleSubSection from "../TitleSubSection"
import SubSectionViver from "../SubSectionViver"
import SubSectionBurocracia from "../SubSectionBurocracia"
import SubSectionComunidade from "../SubSectionComunidade"
import SubSectionCarousel from "../SubSectionCarousel"
import ModeloCards from "../ModeloCards"

const SectionModelo = () => {

	return (
		<Section id="modelo">
            <TitleSubSection className={Style.subtitle}>
                Nosso modelo de negócio
            </TitleSubSection>
            <SubSectionBurocracia />
			<SubSectionViver />
            <ModeloCards />
            <SubSectionCarousel />
            <SubSectionComunidade />
		</Section>
	)
}

export default SectionModelo