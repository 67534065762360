import React from "react"

import Style from "./style.module.css"

import FundoCards from '../FundoCards'
import Section from "../Section"
import TitleSection from "../TitleSection"
import TitleSubSection from "../TitleSubSection"

const SectionFundoImobiliario = () => {
	return (
		<Section id="sobre">
			<div className={Style.container}>
				<TitleSection className={Style.title}>
					LUGGO Fundo de Investimento Imobiliário
				</TitleSection>
				<div>
					<p className={Style.box}>
						<b>O primeiro Fundo de Investimento Imobiliário residencial
							do Brasil é da Luggo</b> e começou com ótimos resultados:
							100% de captação no período de reserva, totalizando
							<b> R$90 milhões</b>. O modelo de negócio desenvolvido
							pela Luggo, startup de locação residencial da MRV, é
							totalmente inovador.
							Mas não se restringe a oferecer uma plataforma de
							locação digital, a proposta é mudar a experiência de
							locação de quem leva uma vida digital.
					</p>
				</div>
				<TitleSubSection className={Style.subtitle}> Como funciona nosso fundo </TitleSubSection>
				<FundoCards />
				<div>
					<p className={Style.disclaimer}>
						Enquanto a MRV compra o terreno e constrói o imóvel, a Luggo
						é a responsável pela locação e administração do ativo. Com
						os apartamentos alugados, ao investidor resta apenas
						receber a renda gerada.
					</p>
				</div>
			</div>
		</Section>
	)
}

export default SectionFundoImobiliario