import React from "react"

import Style from "./style.module.css"

import Section from "../Section"
import PorqueCards from "../PorqueCards"
import Simulator from "../Simulator"

const SectionPorque = () => {
    return (
        <Section bgColor="purple">
            <div className={Style.content}>
                <div className={Style.title}>
                    <h3> Por que investir no FII Luggo? </h3>
                </div>
                <PorqueCards />
            </div>
        </Section>

    );
}

export default SectionPorque