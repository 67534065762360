import React, {useState} from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import cx from 'classnames';

import Style from "./style.module.css"
import ArrowIcon from "../../images/arrow.svg"

const FundoCards = () => {

	const [active1, setActive1] = useState(false)
	const [active2, setActive2] = useState(false)
	const [active3, setActive3] = useState(false)

	const data = useStaticQuery(graphql`
	    query {
	      mrv: file(relativePath: { eq: "mrv-green.png" }) {
	        childImageSharp {
	          fixed {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      },
	      luggo: file(relativePath: { eq: "luggo-purple.png" }) {
	        childImageSharp {
	          fixed {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      },
	      luggoFii: file(relativePath: { eq: "luggo-fii.png" }) {
	        childImageSharp {
	          fixed {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      }
	    }
    `)

    return (
        <div className={Style.Fundo}>
            <div className={Style.content}>
                <div className={Style.logo} onClick={() => setActive1(!active1)}>
                	<div className={!active1 ? Style.logo_inner : cx(Style.logo_inner, Style.active)}>
	                    <div className={Style.logo_front}>{!active1 && <Img fixed={data.mrv.childImageSharp.fixed} /> } </div>
	                    <div className={Style.logo_back}>{active1 && <p> Land bank <br/> Legalização <br/> Construção <br/> Assistência Técnica </p> } </div>
	               	</div>
	               	<div className={Style.plus}> {active1 ? '-' : '+'} </div>

                </div>
                <ArrowIcon className={Style.arrow}/>
                <div className={Style.logo} onClick={() => setActive2(!active2)}>
                	<div className={!active2 ? Style.logo_inner : cx(Style.logo_inner, Style.active)}>
                    	<div className={Style.logo_front}>{!active2 && <Img fixed={data.luggo.childImageSharp.fixed} /> }</div>
                    	<div className={Style.logo_back}>{active2 && <p> Análise de crédito <br/> Locação digital <br/> Administração <br/> Comunidades </p> }</div>
                	</div>
                	<div className={Style.plus}> {active2 ? '-' : '+'} </div>
                </div>
                <ArrowIcon className={Style.arrow}/>
                <div className={Style.logo} onClick={() => setActive3(!active3)}>
                	<div className={!active3 ? Style.logo_inner : cx(Style.logo_inner, Style.active)}>
	                    <div className={Style.logo_front}>{!active3 && <Img fixed={data.luggoFii.childImageSharp.fixed} /> }</div>
	                    <div className={Style.logo_back}>{active3 && <p> Compra o ativo <br/> Conecta investidores </p> }</div>
                    </div>
                    <div className={Style.plus}> {active3 ? '-' : '+'} </div>
                </div>
            </div>
        </div>
    )
}

export default FundoCards