import React from "react"

import Layout from "../components/Layout"
import Hero from "../components/Hero"
import SectionFundoImobiliario from "../components/SectionFundoImobiliario"
import SectionOqueE from "../components/SectionOqueE"
import SectionPorque from "../components/SectionPorque"
import SectionModelo from "../components/SectionModelo"
import SectionAtivos from "../components/SectionAtivos"
import SectionComo from "../components/SectionComo"
import SectionVideo from "../components/SectionVideo"
import SectionVantagens from "../components/SectionVantagens"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Fundo de Investimento Imobiliário" />
    <Hero />
    <SectionFundoImobiliario />
    <SectionPorque />
    <SectionVideo />
    <SectionModelo />
    <SectionOqueE />
    <SectionVantagens />
    <SectionAtivos />
    <SectionComo />
  </Layout>
)

export default IndexPage
