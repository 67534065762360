import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Style from "./style.module.css"

import Section from "../Section"
import TitleSubSection from "../TitleSubSection"
import CertificateIcon from "../../images/certificate.svg"

const SectionOqueE = () => {

	const data = useStaticQuery(graphql`
	    query {
	      house: file(relativePath: { eq: "house.png" }) {
	        childImageSharp {
	          fixed(width: 46) {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      },
	      percent: file(relativePath: { eq: "percent.png" }) {
	        childImageSharp {
	          fixed(width: 46) {
	            ...GatsbyImageSharpFixed
	          }
	        }
	      }
	    }
    `)

	return (
		<Section id="oquee">
			<h2 className={Style.title}>
				Fundo de Investimento Imobiliário
			</h2>
			<div className={Style.subtitle}>
				<TitleSubSection> O que é um Fundo de Investimento Imobiliário ? </TitleSubSection>
			</div>
			<div className={Style.content}>
				<div className={Style.box}>
					<Img fixed={data.house.childImageSharp.fixed} />
					<p className={Style.description}> Os Fundos de Investimento Imobiliário, também conhecidos como FIIs, são um conjunto de recursos financeiros destinados à aplicação em empreendimentos imobiliários. </p>
				</div>
				<div className={Style.box}>
					<CertificateIcon />
					<p className={Style.description}> Um FII reúne pessoas interessadas em <strong>viver da renda de imóveis,</strong> mas, ao invés de comprar e alugar um espaço, optam por comprar cotas e lucrarem proporcionalmente ao valor do aluguel, o que é ainda mais vantajoso se for bem planejado. </p>
				</div>
				<div className={Style.lastbox}>
					<Img fixed={data.percent.childImageSharp.fixed} />
					<p className={Style.description}> Quando você investe em um FII está participando do crescimento do mercado imobiliário, que tende a ser cada vez maior. Além disso, a liquidez desse tipo de investimento é beneficiada pela negociação direta das cotas pela B3. </p>
				</div>
			</div>
		</Section>
	);
}

export default SectionOqueE