import React from "react"

import Style from "./style.module.css"
import LockIcon from "../../images/lock.svg"
import WashIcon from "../../images/wash.svg"
import CoWorkIcon from "../../images/coworking.svg"
import CarIcon from "../../images/car.svg"
import StarIcon from "../../images/start.svg"
import ParkingIcon from "../../images/parking.svg"
import ToolIcon from "../../images/tool.svg"
import WiFiIcon from "../../images/wifi.svg"
import BikeIcon from "../../images/bike.svg"
import GymIcon from "../../images/gym.svg"


const ModeloCards = () => {
    return (
        <div className={Style.container}>
            <div className={Style.card_row}>
                <div className={Style.card}>
                    <WashIcon/>
                    <p>Lavanderia compartilhada</p>
                </div>
                <div className={Style.card}>
                    <CarIcon/>
                    <p>Carsharing</p>
                </div>
                <div className={Style.card}>
                    <BikeIcon/>
                    <p>Bikes Luggo</p>
                </div>
                <div className={Style.card}>
                    <StarIcon/>
                    <p>Limpeza domiciliar</p>
                </div>
                <div className={Style.card}>
                    <GymIcon/>
                    <p>Academia</p>
                </div>
                <div className={Style.card}>
                    <ParkingIcon/>
                    <p>Estacionamento</p>
                </div>
                <div className={Style.card}>
                    <WiFiIcon/>
                    <p>Internet</p>
                </div>
                <div className={Style.card}>
                    <CoWorkIcon/>
                    <p>Coworking</p>
                </div>
                <div className={Style.card}>
                    <ToolIcon/>
                    <p>Home repairs</p>
                </div>
                <div className={Style.card}>
                    <LockIcon/>
                    <p>Locker</p>
                </div>
            </div>
        </div>
    )

}

export default ModeloCards