import React from "react"

import Style from "./style.module.css"

import App from "../../images/phone_app.svg"

const SubSectionBurocracia = () => {

	return (
        <div>
            <div className={Style.content}>
                <div className={Style.image}>
                    <App />
                </div>
                <div className={Style.description}>
                    <h4 className={Style.subtitle}> Plataforma digital sem burocracia</h4>
                    <p >
                        Para alugar com a Luggo, <b>não é necessário fiador, a
                        análise de crédito é imediata e o processo é totalmente
                        online</b>. A assinatura do contrato é digital e o interessado
                        pode visitar o empreendimento de onde estiver, através de
                        um tour virtual. Em muitos casos o processo todo foi concluído
                        em menos de 4 horas.
                    </p>
                    <a className={Style.button} href="https://alugue.luggo.com.br/sobrenos" target="_blank">
                        SAIBA MAIS SOBRE A LUGGO
                    </a>
                </div>

            </div>
        </div>
	)
}

export default SubSectionBurocracia