import React from "react"

import Style from "./style.module.css"
import ComunidadeImage from "../../images/comunidade.svg"

const SubSectionComunidade = () => {
    
	return (
        <div>
            <div className={Style.content}>
                <div className={Style.image}>
                    <ComunidadeImage />
                </div>
                <p className={Style.description}>
                    <h4 className={Style.subtitle}> Comunidades Luggo </h4>

                    <p>O “Viver Luggo” também valoriza a formação de uma comunidade
                    de pessoas com interesses e necessidades em comum, ou seja,
                    seus moradores. As conexões offline também são muito valorizadas,
                    por isso são realizadas diversas ações e eventos visando gerar
                    aproximação entre a marca e as pessoas, fomentando o <b>senso
                    de pertencimento </b> e, consequentemente, a satisfação com o todo.</p>
                </p>
            </div>
        </div>
	)
}

export default SubSectionComunidade