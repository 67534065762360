import React from "react"

import Style from "./style.module.css"

import Section from "../Section"
import TitleSubSection from "../TitleSubSection"
import VantagensCards from "../VantagensCards"
import Comportamento from "../Comportamento/Comportamento"

const SectionVantagens = () => {
	return (
		<Section id="vantagens" bgColor="gray">
            <TitleSubSection> Vantagens do Fundo </TitleSubSection>
			<VantagensCards />
            <Comportamento />
		</Section>
	)
}

export default SectionVantagens