import React from "react"

import Style from "./style.module.css"

const TitleSection = (props) => {
	return (
		<h2 className={Style.title}> {props.children} </h2>
	);
}

export default TitleSection