import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Style from "./style.module.css"
import Img from "gatsby-image/withIEPolyfill"

import TitleSubSection from "../TitleSubSection"

const Comportamento = () => {
    const data = useStaticQuery(graphql`
        query {
          graph: file(relativePath: { eq: "graph1.png" }) {
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed
              }
            }
          },
          graph2: file(relativePath: { eq: "graph2.png" }) {
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed
              }
            }
          },
          graph3: file(relativePath: { eq: "graph3.png" }) {
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed
              }
            }
          },
          graph4: file(relativePath: { eq: "graph4.png" }) {
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
    `)

	return (

        <div className={Style.container}>
            <div>
                <TitleSubSection>
                    Comportamento do mercado de Fundo Imobiliário
                </TitleSubSection>
                <p className={Style.text}>Os investimentos em FII (Fundo de Investimento
                    Imobiliário) são ainda mais desejados em cenários que apresentam uma <b>taxa básica
                    de juros mais baixa</b>. Nestas situações, o número de investidores e o volume de
                    negociações geralmente se eleva muito.
                </p>
            </div>
            <div className={Style.graphContainer}>
                <p className={Style.description}>
                    Com o aumento do volume de negociações, cresce
                    também a liquidez no mercado de FII.
                </p>
                <div className={Style.graphics}>
                    <div className={Style.graphInnerContainer}>
                        <div className={Style.Graph}>
                            <Img objectFit="contain" fixed={data.graph.childImageSharp.fixed}/>
                        </div>
                        <p className={Style.textGraph}>
                            O acumulado do volume anual de negociações
                            em 2019 já ultrapassou o volume total de negociações em 2018.
                        </p>
                    </div>
                    <div className={Style.graphInnerContainer}>
                        <div className={Style.Graph}>
                            <Img objectFit="contain" fixed={data.graph2.childImageSharp.fixed}/>
                        </div>
                        <p className={Style.textGraph}>
                            O número de investidores em crescimento
                            reflete o aumento do volume de negociações de FII.
                        </p>
                    </div>
                </div>
                <p className={Style.fontGraph}>
                    Fonte para ambos os gráficos: (1 e 2) Boletim Imobiliário B3- Novembro 2019 e Bacen.
                </p>
            </div>
            <h4 className={Style.subtitle}>
                E quanto a diferença do FII para tipos mais tradicionais
                de investimento?</h4>
            <p className={Style.text}>
                A poupança, por exemplo, geralmente têm um rendimento considerado baixo, pois sua rentabilidade está presa à variação da taxa Selic. Já o CDB, apesar de atrelado a uma porcentagem do CDI, exige prazos mais longos para que o investidor tenha rendimentos superiores. Nesse quesito o fundo imobiliário é uma escolha que compensa muito mais! O gráfico abaixo mostra que o IFIX (Índice de Fundos de Investimentos Imobiliários da BM&FBOVESPA) teve um rendimento superior ao da própria IBOVESPA.
            </p>
            <div className={Style.graphContainer}>
                <p className={Style.description}>
                    O gráfico abaixo mostra que o IFIX (Índice de Fundos de Investimentos Imobiliários
                    da BM&FBOVESPA) teve um rendimento bem superior ao da própria IBOVESPA.
                </p>
                <div className={Style.graphics}>
                    <div className={Style.graphInnerContainer}>
                        <div className={Style.Graph}>
                            <Img objectFit="contain" fixed={data.graph3.childImageSharp.fixed}/>
                        </div>
                        <p className={Style.textGraphBlack}>
                           Com projeções para Selic a 4,75% no final de 2023, o volume captado tende a aumentar pois o investidor buscará taxas mais atrativas.
                        </p>
                    </div>
                    <div className={Style.graphInnerContainer}>
                        <div className={Style.Graph}>
                            <Img objectFit="contain" fixed={data.graph4.childImageSharp.fixed}/>
                        </div>                    
                    </div>
                </div>
                <p className={Style.fontGraph}>Fonte: (3) Boletim Imobiliário B3- Novembro 2019 e Bacen. (4) Sistema de Expectativas de Mercado do Banco Central do Brasil (03/01/2020).</p>
            </div>
        </div>
	);
}

export default Comportamento