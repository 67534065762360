import React from "react"

import Style from "./style.module.css"

const Section = (props) => {

	let bgColor = "#ffffff"
	if(props.bgColor === "gray")
		bgColor = "#F5F5F5"


	if(props.bgColor === "purple")
	bgColor = "#3D005E"

	return (
		<section id={props.id} style={{backgroundColor: bgColor}} className={Style.section}>
			<div className="container">
				{props.children}
			</div>
		</section>
	)
}

export default Section