import React from "react"

import Style from "./style.module.css"
import MoneyIcon from "../../images/money_why.svg"
import LockIcon from "../../images/lock_why.svg"
import LocationIcon from "../../images/localizacao_why.svg"
import DownIcon from "../../images/down_why.svg"
import RiskIcon from "../../images/risk.svg"


const PorqueCards = () => {
    return (
        <div className={Style.content}>
            <div className={Style.card}>
                <MoneyIcon />
                <p>Velocidade de locação acima do mercado</p>
            </div>
            
            <div className={Style.card}>
                <LockIcon />
                <p>Gestão profissionalizada dos ativos</p>
            </div>

            <div className={Style.card}>
                <DownIcon />
                <p>Menor custo de aquisição</p>
            </div>
            
            <div className={Style.card}>
                <LocationIcon />
                <p>Empreendimentos bem localizados</p>
            </div>

            <div className={Style.card}>
                <RiskIcon />
                <p>Pulverização do risco</p>
            </div>

            
        </div>
    );
}

export default PorqueCards